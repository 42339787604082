const facts = [
  {
    title: "Remboursement assurance de prêt",
    link: "fact1",
  },
  {
    title: "Droit à l'oubli",
    link: "fact2",
  },
  {
    title: "Questionnaire médical",
    link: "fact3",
  },
  {
    title: "Assurance de prêt est refusée",
    link: "fact4",
  },
];

export default facts;
